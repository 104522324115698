<template>
	<div id="myAssistant" v-loading="loading" :element-loading-text="loadingText">
		<div class="top">
			<div class="title">
				<i></i>
				我的助教
			</div>
			<div class="options">
				<el-button type="primary" size="mini" @click="batchCreation">批量创建</el-button>
				<el-button type="primary" size="mini" @click="editInfo=true,assistantRow={}">新增助教</el-button>
			</div>
		</div>
		<div class="list">
			<!-- 列表 -->
			<el-table :data="assistantList" style="width: 100%">
				<el-table-column fixed prop="id" label="编号" width="80" align="cneter" sortable></el-table-column>
				<el-table-column prop="fullName" label="名称" min-width="100" align="cneter"></el-table-column>
				<el-table-column prop="phoneNumber" label="电话" min-width="120" align="cneter"></el-table-column>
				<el-table-column prop="isDel" label="账号状态" min-width="100" sortable>
					<template slot-scope="scope">
						<template v-if="scope.row.isDel == 0"><el-tag size="mini" type="success">启用</el-tag> </template>
						<template v-else> <el-tag size="mini" type="danger">停用</el-tag></template>
					</template>
				</el-table-column>
				<el-table-column prop="updateTime" label="修改时间" min-width="180" sortable>
					<template slot-scope="scope">
						{{$moment(scope.row.updateTime).format('lll')}}
					</template>
				</el-table-column>
				<!-- <el-table-column prop="name" label="当前配额" min-width="100" align="cneter"></el-table-column>
				<el-table-column prop="name" label="执行数量" min-width="100" align="cneter"></el-table-column> -->
				<el-table-column fixed="right" label="操作" width="140" align="center">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="editAssistant(scope.row)">修改信息</el-button>
						<el-button type="text" size="small" @click="adjustmentOfQuota(scope.row)">调整配额</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
				:page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total" background>
			</el-pagination>
		</div>

		<!-- 修改助教信息 -->
		<el-dialog custom-class="editInfo" v-if="editInfo" :visible.sync="editInfo" center
			:title="assistantRow.type ?assistantRow.type:'新增助教'" :close-on-click-modal="false">
			<el-form ref="form" :model="assistantRow" label-position="top">

				<el-form-item label="手机号">
					<el-input v-model="assistantRow.phoneNumber" :disabled="assistantRow.type "
						placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="助教姓名">
					<el-input v-model="assistantRow.fullName" placeholder="请输入助教姓名"></el-input>
				</el-form-item>
				<el-form-item label="账号状态">
					<el-switch v-model="assistantRow.isDel" active-color="#13ce66" inactive-color="#ff4949" size="mini"
						active-value="0" inactive-value="1" active-text="启用" inactive-text="停用">
					</el-switch>
				</el-form-item>
			</el-form>
			<div class="tips">
				提示<br>
				1.助教注册成功后默认密码为：123456 <br>
				2.注册成功后，助教手机号不可修改
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editInfo = false,assistantRow = {}">取 消</el-button>
				<el-button type="primary" @click="editInfoSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		projectAssistantUserList,
		saveProjectAssistant,
		updateProjectUser
	} from '@/request/api.js';
	export default {
		name: 'myAssistant',
		data() {
			return {
				loading: false,
				loadingText: '加载中...',

				assistantList: [],

				assistantRow: null,
				editInfo: false,

				total: 30,
				pageSize: 10,
				current: 1,
			}
		},
		created() {},
		mounted() {
			this.getAListOfTeachingAssistants();
		},
		methods: {
			// 创建、修改助教信息
			editInfoSubmit() {
				this.editInfo = false;
				this.assistantRow.projectId = this.$route.query.pId;
				// 修改助教信息
				if (this.assistantRow.id) {
					let data = this.assistantRow;
					updateProjectUser(data).then(res => {
						if (res.code == 200) {
							this.assistantRow = null;
							this.$message.success("操作成功");
							this.getAListOfTeachingAssistants();
						} else {
							this.$message.error(res.msg);
							this.getAListOfTeachingAssistants();
						}
					});
				} else {
					let data = [this.assistantRow];
					saveProjectAssistant(data).then(res => {
						if (res.code == 200) {
							this.assistantRow = null;
							this.$message.success("操作成功");
							this.getAListOfTeachingAssistants();
						} else {
							this.$message.error(res.msg);
							this.getAListOfTeachingAssistants();
						}
					});
				}
			},

			// 修改助教信息
			editAssistant(row) {
				this.assistantRow = row;
				if (row.id) this.assistantRow.type = '修改信息'
				this.editInfo = true;
			},

			// 获取执行机构助教列表
			getAListOfTeachingAssistants() {
				this.loading = true;
				this.loadingText = '加载中...';
				projectAssistantUserList({
					projectId: this.$route.query.pId
				}).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.assistantList = res.data
					};
				});
			},
			// 分页
			handleSizeChange(val) {
				this.pageSize = val;
				this.getAListOfTeachingAssistants();
			},
			handleCurrentChange(val) {
				this.current = val;
				this.getAListOfTeachingAssistants();
			},

			batchCreation() {
				this.$router.push({
					name: 'batchCreateAssistant',
					query: {
						pId: this.$route.query.pId
					}
				})
			},

			// 调整配额
			adjustmentOfQuota() {
				this.$router.push({
					name: 'projectTask',
					query: {
						pId: this.$route.query.pId
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	#myAssistant {
		padding: 20px 30px;

		.top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #fff;
			border-radius: 10px;
			padding: 10px 20px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

			.title {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}

			.options {
				display: flex;
				align-items: center;

				.el-button {
					margin-left: 20px;
				}

				.el-link {
					font-size: 12px;
				}
			}
		}

		.list {
			background-color: #fff;
			border-radius: 10px;
			padding: 20px;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			margin: 20px 0;

			.title {
				font-size: 16px;
				position: relative;


			}

			.el-pagination {
				text-align: center;
				margin-top: 20px;
			}

			.options {
				display: flex;
				justify-content: center;
				margin-top: 20px;

				.el-button {}
			}
		}
	}

	::v-deep .editInfo {
		width: 400px;
		margin: 0 !important;
		left: 50%;
		top: 50%;
		max-height: 95vh;
		transform: translate(-50%, -50%);

		.el-dialog__body {
			padding: 10px 20px;
		}

		.tips {
			font-size: 12px;
			color: #999;
		}

		.el-form-item {
			margin: 0 0 10px 0;
		}

		.el-form-item__label {
			padding: 0;
			line-height: 1;
			margin: 10px 0;
		}

		.el-dialog__footer {
			padding-top: 0;
		}
	}
</style>